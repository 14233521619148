import * as React from "react";
import styled from "styled-components";
import "normalize.css";
import Header from "../components/Header";
import SplashScreen from "../components/SplashScreen";
import Footer from "../components/Footer";
import {
  ContentWorkDifferentlyTogether,
  ContentWorkDifferentlyTogetherImage,
  ContentDiscoverDigitalPossibilities,
  ContentDiscoverDigitalPossibilitiesImage,
  ContentDiscoverDigitalPossibilitiesAdditionalImage,
  ContentCreateNew,
  ContentCreateNewAdditionalImage,
  ContentMaintainOld,
  ContentMaintainOldAdditionalImage,
  ContentSearchCollaboration,
  ContentSearchCollaborationImage,
  ContentPositiveFuture,
} from "../components/TextSections";
import ReportContainer, {
  StyledIEUnavailable,
} from "../components/ReportContainer";
import Slider from "../components/Slider";
import { colors, fontSizes } from "../components/common";
import { StaticImage } from "gatsby-plugin-image";

import imgCiteTellerrand from "../images/zitat-tellerand.svg";
import imgScribbleCross from "../images/scribble-cross.svg";

import {
  AnimationBuilding,
  AnimationDynamic,
  AnimationHand,
  AnimationTodayTomorrow,
} from "../components/Animations";
import { Helmet } from "react-helmet";

const Wrapper = styled.main`
  @import url("https://p.typekit.net/p.css?s=1&k=jgt8bgu&ht=tk&f=26494.26500&a=6698789&app=typekit&e=css");

  @font-face {
    font-family: "ingra";
    src: url("https://use.typekit.net/af/ef0034/00000000000000007735a7be/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/ef0034/00000000000000007735a7be/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
        format("woff"),
      url("https://use.typekit.net/af/ef0034/00000000000000007735a7be/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
        format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: "ingra";
    src: url("https://use.typekit.net/af/6c7559/00000000000000007735a7c4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/6c7559/00000000000000007735a7c4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff"),
      url("https://use.typekit.net/af/6c7559/00000000000000007735a7c4/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
  }

  font-family: "ingra", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: ${fontSizes.text};
  color: ${colors.text};

  display: block;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: auto;

  * {
    box-sizing: border-box;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
    * {
      display: none;
    }
  }
`;

// markup
const IndexPage = () => {
  return (
    <>
      <Wrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Geschäftsbericht 2020 – Aachener Siedlungs- und Wohnungsgesellschaft
            mbH
          </title>
        </Helmet>
        <Header>
          <SplashScreen />
        </Header>

        <ContentWorkDifferentlyTogether
          header="Wir arbeiten anders zusammen."
          text="Wenn der Wind des Wandels weht, bauen die einen Schutzmauern, die anderen bauen Windmühlen, sagt ein chinesisches Sprichwort. Die Aachener baut Windmühlen und eine Organisation, die bereit für die Herausforderungen von morgen ist."
          colWidthText="50%"
          paddingLeft={"single"}
          paddingRight={"double"}
        >
          <ContentWorkDifferentlyTogetherImage>
            <AnimationDynamic />
          </ContentWorkDifferentlyTogetherImage>
        </ContentWorkDifferentlyTogether>

        <ContentDiscoverDigitalPossibilities
          left
          header="Wir entdecken digitale Möglichkeiten."
          text="Die Immobilienbranche, steht bei digitalen Themen noch ganz am Anfang der Entwicklung. Die Aachener hat in diesem besonderen Jahr schon erfolgreich angefangen und wird genau so weitermachen. "
          colWidthText="570px"
          paddingLeft={"double"}
        >
          <ContentDiscoverDigitalPossibilitiesImage>
            <AnimationHand />
          </ContentDiscoverDigitalPossibilitiesImage>
        </ContentDiscoverDigitalPossibilities>

        <ContentDiscoverDigitalPossibilitiesAdditionalImage>
          <img src={imgCiteTellerrand} alt="" />
        </ContentDiscoverDigitalPossibilitiesAdditionalImage>

        <ContentCreateNew
          header="Wir gestalten Neues."
          text="Die Aachener gewinnt mit ihren Unternehmenswerten und ihrer Erfahrung im Erbbaurecht den Ausschreibungs&shy;wettbewerb <em>„Sürther Feld“</em>. Hier entstehen zwei Mehrfamilienhäuser mit innovativen und sozialen Wohnformen."
          colWidthText="432px"
          paddingRight={"double"}
          paddingLeft={"none"}
        >
          <img
            src={imgScribbleCross}
            alt=""
            width={53}
            className="decoration"
          />
          <StaticImage
            src="../images/image-wir-gestalten-neues-3x.jpg"
            alt="Wir gestalten Neues"
            loading="lazy"
            layout="constrained"
            objectFit={"contain"}
            objectPosition={"0 50%"}
            width={1000}
            style={{
              alignSelf: "flex-start",
            }}
          />
        </ContentCreateNew>

        <ContentCreateNewAdditionalImage>
          <AnimationBuilding />
        </ContentCreateNewAdditionalImage>

        <ContentMaintainOld
          left
          header="Wir erhalten Altes."
          text="In der Siedlung Kissinger Straße in Düsseldorf sorgen Menschen mit Herz, Hand und Verstand dafür, dass Bestandsimmobilien aus den 60ern energetisch bereit für die Zukunft werden und zusätzlich dringend benötigter Wohnraum geschaffen wird."
          colWidthText="570px"
          paddingLeft={"single"}
          paddingRight={"single"}
        >
          <StaticImage
            src="../images/image-wir-erhalten-altes-3x.png"
            alt="Wir erhalten altes"
            loading="lazy"
            layout="constrained"
            objectFit={"contain"}
            width={700}
            style={{
              alignSelf: "flex-end",
            }}
          />
        </ContentMaintainOld>

        <ContentMaintainOldAdditionalImage>
          <StaticImage
            src="../images/image-wir-erhalten-altes-2-3x.jpg"
            alt="Wir erhalten altes"
            loading="lazy"
            layout="constrained"
            width={500}
          />
        </ContentMaintainOldAdditionalImage>

        <ContentSearchCollaboration
          header="Wir suchen Austausch"
          text="Im Gespräch untereinander und mit anderen finden wir das Gemeinsame und Lösungen, damit zukünftige Herausforderungen der Branche mit unserer Unternehmens-DNA bezahlbarer Wohnraum vereinbar bleiben."
          colWidthText="570px"
          paddingRight={"single"}
          paddingLeft={"none"}
        >
          <ContentSearchCollaborationImage>
            <StaticImage
              src="../images/image-wir-suchen-austausch-3x.jpg"
              alt="Wir suchen Austausch"
              loading="lazy"
              layout="constrained"
              objectFit={"contain"}
              objectPosition={"0 50%"}
              height={1410}
              style={{
                alignSelf: "flex-start",
              }}
            />
            <AnimationTodayTomorrow className={"animation"} />
          </ContentSearchCollaborationImage>
        </ContentSearchCollaboration>

        <ContentPositiveFuture
          left
          header="Wir sehen positiv in die Zukunft."
          text="Mit der strategischen Neuausrichtung sind bei der Aachener Zukunftsfragen – etwa zur Digitalisierung und zum Klimawandel – noch stärker in den Fokus gerückt. Deshalb lassen wir die Aachener von morgen sprechen: unsere Azubis."
          colWidthText="660px"
          paddingLeft={"double"}
        />

        <Slider />
        <ReportContainer />
        <Footer />
      </Wrapper>
      <StyledIEUnavailable />
    </>
  );
};

export default IndexPage;
