import * as React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { breaks } from "./common";
import leftArrowImg from "../images/arrow-left.svg";
import rightArrowImg from "../images/arrow-right.svg";
import "../css/slick-theme.css";
import "../css/slick.css";

import { StaticImage } from "gatsby-plugin-image";

const SliderWrapper = styled.section`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  margin: 0 auto 4em auto;

  @media (min-width: ${breaks.lg}) {
    padding: 3.3em 6em;
  }

  // Hide in IE11
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
    width: 30px;
    height: 48px;
  }

  .slick-prev:before,
  .slick-next:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .slick-next {
    right: 20px;

    @media (min-width: ${breaks.lg}) {
      right: -5%;
    }
  }

  .slick-next:before {
    background-image: url(${rightArrowImg});
  }

  .slick-prev {
    left: 20px;

    @media (min-width: ${breaks.lg}) {
      left: -5%;
    }
  }

  .slick-prev:before {
    background-image: url(${leftArrowImg});
  }

  // Slide image visibility

  .visible-desktop {
    display: none;

    @media (min-width: ${breaks.lg}) {
      display: block;
    }
  }

  .visible-mobile {
    display: block;

    @media (min-width: ${breaks.lg}) {
      display: none;
    }
  }
`;

const Slide = (props) => {
  return <div className={props.className}>{props.children}</div>;
};

const StyledSlide = styled(Slide)`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1em;

  @media (min-width: ${breaks.md}) {
    padding: 5em 0;
  }
`;

export default class SimpleSlider extends React.Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      adaptiveHeight: false,
      speed: 750,
      autoplay: false,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const sliderHeightDesktop = 520;
    const sliderHeightMobile = 460;
    const imgWrapperStyleMobile = {
      maxWidth: "90%",
    };

    return (
      <SliderWrapper>
        <Slider {...settings}>
          <StyledSlide>
            <StaticImage
              src="../images/slider/leon-desktop@3x.png"
              alt="Leon"
              height={sliderHeightDesktop}
              layout="constrained"
              objectFit="contain"
              placeholder="none"
              className="visible-desktop"
            />
            <StaticImage
              src="../images/slider/leon-mobile@3x.png"
              alt="Leon"
              height={sliderHeightMobile}
              layout="constrained"
              objectFit="contain"
              placeholder="none"
              style={imgWrapperStyleMobile}
              className="visible-mobile"
            />
          </StyledSlide>

          <StyledSlide>
            <StaticImage
              src="../images/slider/gina-desktop@3x.png"
              alt="Gina"
              height={sliderHeightDesktop}
              layout="constrained"
              objectFit="contain"
              placeholder="none"
              className="visible-desktop"
            />
            <StaticImage
              src="../images/slider/gina-mobile@3x.png"
              alt="Gina"
              height={sliderHeightMobile}
              layout="constrained"
              objectFit="contain"
              placeholder="none"
              style={imgWrapperStyleMobile}
              className="visible-mobile"
            />
          </StyledSlide>

          <StyledSlide>
            <StaticImage
              src="../images/slider/paul-desktop@3x.png"
              alt="Paul"
              height={sliderHeightDesktop}
              layout="constrained"
              objectFit="contain"
              placeholder="none"
              className="visible-desktop"
            />
            <StaticImage
              src="../images/slider/paul-mobile@3x.png"
              alt="Paul"
              height={sliderHeightMobile}
              layout="constrained"
              objectFit="contain"
              placeholder="none"
              style={imgWrapperStyleMobile}
              className="visible-mobile"
            />
          </StyledSlide>

          <StyledSlide>
            <StaticImage
              src="../images/slider/maria-desktop@3x.png"
              alt="Maria"
              height={sliderHeightDesktop}
              layout="constrained"
              objectFit="contain"
              placeholder="none"
              className="visible-desktop"
            />
            <StaticImage
              src="../images/slider/maria-mobile@3x.png"
              alt="Maria"
              height={sliderHeightMobile}
              layout="constrained"
              objectFit="contain"
              placeholder="none"
              style={imgWrapperStyleMobile}
              className="visible-mobile"
            />
          </StyledSlide>
        </Slider>
      </SliderWrapper>
    );
  }
}
