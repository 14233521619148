import * as React from "react";
import styled from "styled-components";
import { fontSizes, colors, breaks } from "./common";
import { Fade } from "react-reveal";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;

  ${(props) =>
    props.colWidth
      ? `
        flex: 0 0 ${props.colWidth};
    `
      : ``};

  @media (max-width: ${breaks.md}) {
    flex-basis: 100%;
    width: 100%;
    justify-content: flex-start;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .text {
      max-width: 100%;
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;

  @media (min-width: ${breaks.md}) {
    flex-direction: ${(props) => (props.left ? "row" : "row-reverse")};
    flex-wrap: wrap;
  }

  @media (min-width: ${breaks.lg}) {
    ${(props) => (props.paddingLeft === "none" ? `padding-left: 0;` : null)}
    ${(props) => (props.paddingLeft === "single" ? `padding-left: 5%;` : null)}
    ${(props) => (props.paddingLeft === "double" ? `padding-left: 10%;` : null)}
    ${(props) => (props.paddingRight === "none" ? `padding-right: 0%;` : null)}
    ${(props) =>
      props.paddingRight === "single" ? `padding-right: 5%;` : null}
    ${(props) =>
      props.paddingRight === "double" ? `padding-right: 10%;` : null}
  }

  // Hide in IE11
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
  }
`;

const TextSection = (props) => {
  return (
    <Row
      left={props.left}
      paddingLeft={props.paddingLeft}
      paddingRight={props.paddingRight}
    >
      <Column className={props.className} colWidth={props.colWidthText}>
        <Fade bottom>
          <div className="text">
            <h3>{props.header}</h3>
            <div dangerouslySetInnerHTML={{ __html: props.text }} />
          </div>
        </Fade>
      </Column>
      <Column className={props.className}>{props.children}</Column>
    </Row>
  );
};

const StyledTextSection = styled(TextSection)`
  color: ${colors.text};
  font-size: ${fontSizes.text};
  font-weight: normal;
  line-height: 1.2;
  position: relative;

  @media (max-width: ${breaks.md}) {
    font-size: 18px;
    line-height: 1.3;
    height: auto;
  }

  h3 {
    font-size: 52px;
    line-height: 1.1;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0.5em;

    @media (max-width: ${breaks.md}) {
      font-size: 36px;
    }
  }

  .gatsby-image-wrapper {
    max-width: 100%;
  }

  // Hightlighted text
  em {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 12%,
      ${colors.highlight} 12%,
      ${colors.highlight} 88%,
      rgba(255, 255, 255, 0) 88%
    );
    font-style: normal;
  }
`;

export const ContentWorkDifferentlyTogether = styled(StyledTextSection)`
  padding: 2em 0;
  align-items: center;

  @media (min-width: ${breaks.lg}) {
    padding: 3.3em 0 9.9em;
  }
`;

export const ContentWorkDifferentlyTogetherImage = styled.div`
  @media (min-width: ${breaks.md}) {
    max-width: 400px;
  }
`;

export const ContentDiscoverDigitalPossibilities = styled(StyledTextSection)`
  @media (min-width: ${breaks.lg}) {
    h3 {
      padding-top: 1em;
    }
  }
`;

export const ContentDiscoverDigitalPossibilitiesImage = styled.div`
  padding: 2em 0;
  position: relative;
  margin-bottom: 0;
  width: 100%;

  @media (min-width: ${breaks.lg}) {
    position: absolute;
    width: 70vw;
    top: -6.6em;
    right: 0;
    z-index: -1;

    // Fix aspect ratio box
    &::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: ${(431 / 1029) * 100}%;
    }

    & > * {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  @media (min-width: ${breaks.xl}) {
    top: -9.9em;
  }
  @media (min-width: ${breaks.xxxl}) {
    width: 100%;
    left: -10%;
  }
`;

export const ContentDiscoverDigitalPossibilitiesAdditionalImage = styled.div`
  margin: 0 15%;
  text-align: center;

  @media (min-width: ${breaks.lg}) {
    margin-top: 1.5em;
    text-align: right;
  }

  img {
    max-width: 100%;
  }
`;

export const ContentCreateNew = styled(StyledTextSection)`
  .decoration {
    position: absolute;
    z-index: 1;
    top: -0.5em;
    left: 40%;
    height: 1em;

    @media (min-width: ${breaks.md}) {
      top: -1em;
      left: 40%;
      height: 2em;
    }

    @media (min-width: ${breaks.lg}) {
      top: 50px;
      left: 40%;
      height: auto;
    }
  }

  div.text {
    padding: 0 0 3em 0;
  }

  @media (min-width: ${breaks.md}) {
    .gatsby-image-wrapper {
      height: 100%;
      width: auto;
      margin: 0 3.3em 0 0;
    }
  }

  @media (min-width: ${breaks.lg}) {
    .gatsby-image-wrapper {
      margin: 3.3em 3.3em 3.3em 0;
    }
  }
`;

export const ContentCreateNewAdditionalImage = styled.div`
  width: 100%;
  position: relative;

  @media (min-width: ${breaks.md}) {
    position: relative;
    width: 70vw;
    max-width: 70%;
    top: -150px;
    margin-left: auto;
    z-index: -1;

    // Fix aspect ratio box
    &::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: ${(380 / 1040) * 100}%;
    }

    & > * {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
`;

export const ContentMaintainOld = styled(StyledTextSection)`
  justify-content: flex-start;

  h3 {
    margin-top: 0.5em;
  }

  @media (min-width: ${breaks.md}) {
    h3 {
      margin-top: 0em;
    }
    .text {
      padding: 0 3.3em 0 0;
    }
  }

  @media (max-width: ${breaks.md}) {
    .text {
      padding-bottom: 2em;
    }
  }
`;

export const ContentMaintainOldAdditionalImage = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: ${breaks.md}) {
    display: block;
    margin-top: 0%;
    margin-left: 5%;
    margin-bottom: 10%;
    max-width: 50%;
  }

  @media (min-width: ${breaks.xl}) {
    margin-top: -15%;
  }
`;

export const ContentSearchCollaboration = styled(StyledTextSection)`
  margin-bottom: 4em;

  div.text {
    padding: 0;
  }

  @media (min-width: ${breaks.md}) {
    div.text {
      padding: 0 3.3em;
    }
  }
`;

export const ContentSearchCollaborationImage = styled.div`
  .animation {
    position: absolute;
    width: auto;
    right: 0;
    bottom: -50px;

    max-width: 250px;
  }

  @media (min-width: ${breaks.xxl}) {
    div.animation {
      max-width: 350px;
    }
  }
`;

export const ContentPositiveFuture = styled(StyledTextSection)``;

export default StyledTextSection;
