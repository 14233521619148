import * as React from "react";
import styled from "styled-components";
import { fontSizes, colors, breaks } from "./common";

const Footer = (props) => {
  return (
    <footer className={props.className}>
      <Branding>
        &copy; 2021 <br />
        Aachener Siedlungs- und Wohnungsgesellschaft mbH
      </Branding>
      <LinkWrapper>
        <a
          href="https://www.aachener-swg.de/kontakt/kontakt.html"
          target="_new"
        >
          Kontakt
        </a>
        <a href="https://www.aachener-swg.de/datenschutz.html" target="_new">
          Datenschutz
        </a>
        <a href="https://www.aachener-swg.de/impressum.html" target="_new">
          Impressum
        </a>
      </LinkWrapper>
    </footer>
  );
};

const Branding = styled.div`
  flex: 1;
  font-size: inherit;

  br {
    display: none;
  }

  @media (max-width: ${breaks.md}) {
    padding: 1em 0;

    br {
      display: block;
    }
  }
`;
const LinkWrapper = styled.div`
  a,
  a:visited {
    color: ${colors.text};
    text-decoration: none;
    font-size: inherit;
    font-weight: inherit;
    padding: 0 1em;

    @media (max-width: ${breaks.md}) {
      padding: 0.5em 0;
    }
  }

  a:active,
  a:hover {
    color: ${colors.highlight};
  }

  @media (max-width: ${breaks.md}) {
    display: flex;
    flex-direction: column;
  }
`;

const StyledFooter = styled(Footer)`
  font-size: ${fontSizes.footer};
  line-height: 1.2;
  font-weight: 400;
  box-sizing: border-box;
  width: 100%;
  height: 4.65em;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 2em;

  @media (max-width: ${breaks.md}) {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
  }
`;

export default StyledFooter;
