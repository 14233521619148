import * as React from "react";
import Lottie from "lottie-react";
import buildingAnimationSplashScreen from "../animations/Element_gebaeude_hero/data.json";
import buildingAnimation from "../animations/Element_gebaeude/data.json";
import dynamicAnimation from "../animations/Element_dynamik/data.json";
import handAnimation from "../animations/Element_haende/data.json";
import todayTomorrowAnimation from "../animations/Element_heutundmorgen/data.json";

export const AnimationBuildingSplashScreen = (props) => {
  return (
    <Lottie
      {...props}
      autoplay={true}
      loop={0}
      animationData={buildingAnimationSplashScreen}
    />
  );
};

export const AnimationDynamic = () => {
  return (
    <Lottie
      loop={0}
      animationData={dynamicAnimation}
      interactivity={{
        mode: "scroll",
        actions: [
          {
            visibility: [0, 0],
            type: "stop",
            frames: [0],
          },
          {
            visibility: [0, 1.0],
            type: "loop",
            frames: [0],
          },
        ],
      }}
    />
  );
};

export const AnimationBuilding = () => {
  return (
    <Lottie
      loop={0}
      animationData={buildingAnimation}
      interactivity={{
        mode: "scroll",
        actions: [
          {
            visibility: [0, 0],
            type: "stop",
            frames: [0],
          },
          {
            visibility: [0.2, 1.0],
            type: "loop",
            frames: [0],
          },
        ],
      }}
    />
  );
};

export const AnimationTodayTomorrow = (props) => {
  return (
    <Lottie
      loop={0}
      animationData={todayTomorrowAnimation}
      interactivity={{
        mode: "scroll",
        actions: [
          {
            visibility: [0, 0],
            type: "stop",
            frames: [0],
          },
          {
            visibility: [0, 1.0],
            type: "loop",
            frames: [0],
          },
        ],
      }}
      className={props.className}
    />
  );
};

export const AnimationHand = () => {
  return (
    <Lottie
      loop={0}
      animationData={handAnimation}
      interactivity={{
        mode: "scroll",
        actions: [
          {
            visibility: [0, 0],
            type: "stop",
            frames: [0],
          },
          {
            visibility: [0.25, 1.0],
            type: "loop",
            frames: [0],
          },
        ],
      }}
    />
  );
};
