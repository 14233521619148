import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import scrollArrow from "../images/arrow-down.svg";
import { fontSizes, breaks, colors } from "./common";
import { AnimationBuildingSplashScreen } from "./Animations";

import imgSplashScreenDecoration from "../images/zeiten-im-wandel.svg";

const SplashScreen = (props) => {
  return (
    <div className={props.className}>
      <SplashImageContainer>
        <StaticImage
          src={"../images/image-stage-3x.jpg"}
          alt={""}
          width={1920}
          height={1110}
          layout={"constrained"}
          objectFit={"cover"}
          objectPosition={"0% 100%"}
        />
        <AnimationBuildingSplashScreen
          style={{
            position: "absolute",
            bottom: "-5%",
            left: "-3%",
            width: "100%",
          }}
        />
      </SplashImageContainer>

      <FlareImageWrapper>
        <img src={imgSplashScreenDecoration} alt="" width={314} />
      </FlareImageWrapper>

      <TextBox>
        <h2>
          2020 &mdash;
          <br /> ein Jahr,
          <br /> das verändert hat.
        </h2>
        <span>Die Aachener im Umbruch.</span>
      </TextBox>

      <ScrollArrow src={scrollArrow} />
    </div>
  );
};

const FlareImageWrapper = styled.div`
  position: absolute;
  height: auto;
  right: 0;
  top: -1.8em;
  width: 136px;

  @media (min-width: ${breaks.md}) {
    right: 1em;
    width: 180px;
  }

  @media (min-width: ${breaks.lg}) {
    top: 0;
  }

  @media (min-width: ${breaks.xl}) {
    top: 0;
    width: 240px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const ScrollArrow = styled.img`
  position: absolute;
  bottom: -40px;
  right: 1em;
  height: 60px;
  width: auto;

  @media (min-width: ${breaks.md}) {
    height: 80px;
  }

  @media (min-width: ${breaks.lg}) {
    height: auto;
    width: 10%;
    bottom: 0;
    right: 0;
  }
`;

const TextBox = styled.div`
  width: 42%;
  background: white;
  font-size: ${fontSizes.text};
  color: black;
  height: auto;
  padding: 1em;
  bottom: 3.7em;
  position: absolute;
  left: 2em;

  h2 {
    margin: 0;
    font-size: ${fontSizes.headers};
  }

  span {
    display: block;
    margin-top: 0.6em;
    color: ${colors.highlight};
    font-weight: 700;
  }

  br:first-child {
    display: none;
  }

  br:nth-child(2) {
    display: block;
  }

  @media (max-width: ${breaks.md}) {
    position: relative;
    width: 95%;
    top: 1%;
    bottom: 0;
    right: auto;
    left: 5%;
    margin-top: -10%;

    h2 {
      font-size: 36px;
      padding-bottom: 0.25em;
    }

    span {
      font-size: 20px;
      font-weight: 700;
    }

    br:first-child {
      display: block;
    }

    br:nth-child(2) {
      display: none;
    }
  }

  @media (max-width: ${breaks.xl}) {
    h2 {
      font-size: 36px;
      padding-bottom: 0.25em;
    }
  }
`;

const SplashImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 90%;

  @media (max-width: ${breaks.md}) {
    width: calc(100% - 0.5em);
    min-height: 250px;
    top: 0.5em;
    right: 0.5em;
    margin-bottom: -3em;
  }
`;

const StyledSplashScreen = styled(SplashScreen)`
  position: relative;
  padding: 0;
  max-height: 85vh;

  @media (max-width: ${breaks.md}) {
    height: auto;
  }

  @media (min-width: ${breaks.xl}) {
    overflow: hidden;
  }

  // Hide in IE11
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
  }
`;

export default StyledSplashScreen;
